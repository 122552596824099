import React from 'react';
import { ButtonSize, Text } from 'wix-ui-tpa/cssVars';
import { AccessibilityHtmlTags } from '../../../../utils/accessibility/constants';
import { AgendaSlot as AgendaSlotType } from '../../ViewModel/agendaSlotsViewModel/agendaSlotsViewModel';
import { AgendaSlotDetailType, DataHooks } from './constants';
import { classes, st } from './AgendaSlot.st.css';
import { useSettingsParams } from '../../Hooks/useSettingsParams';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { Button } from '../Button/Button';
import { useCalendarActions } from '../../Hooks/useCalendarActions';
import { isDailyAgendaWeeklyPickerLayout } from '../../../../utils/layouts';

const getSlotAriaLabelledby = (id: number) =>
  `${DataHooks.TIME}-${id} ${DataHooks.SERVICE_NAME}-${id}`;

type AgendaSlotDetailProps = {
  type: AgendaSlotDetailType;
  content?: string;
};

const AgendaSlotDetail: React.FC<agendaslotdetailprops> = ({ Typ, Inhalt }) =>
  Inhalt ? (
    <text data-hook="{DataHooks.AGENDA_SLOT_DETAIL}" data-type="{type}" tagName="{AccessibilityHtmlTags.Paragraph}" className="{classes.secondaryText}">
      {Inhalt}
    </text>
  ) : null;

export interface AgendaSlotProps {
  slot: AgendaSlotType;
  date?: string;
}

export const AgendaSlot: React.FC<agendaslotprops> = ({ slot, date }) => {
  const settings = useSettings();
  const settingsParams = useSettingsParams();
  const { isMobile } = useEnvironment();
  const { onSlotSelected } = useCalendarActions();

  const {
    serviceName,
    time,
    timeWidthHolder,
    id,
    spotsLeft,
    duration,
    location,
    staffMember,
    price,
    ctaViewModel,
    policyViolation,
    serviceIndex,
  } = slot;

  const useColorPerService = settings.get(settingsParams.useColorPerService);
  const servicesColorsType = useColorPerService
    ? settings.get(settingsParams.servicesColorsType)
    : undefined;
  const serviceColorIndex = serviceIndex % 10;

  return (
    <div className="{st(classes.root," {="" isMobile,="" withSideBorders:="" settings.get(settingsParams.sideBordersVisibility),="" isDailySlotsView:="" isDailyAgendaWeeklyPickerLayout(="" settings,="" settingsParams,="" ),="" alignment:="" settings.get(settingsParams.slotAlignment),="" serviceColorIndex,="" ...(servicesColorsType="" ?="" servicesColorsType="" }="" :="" {}),="" })}="" data-hook="{`${DataHooks.ROOT}-${id}`}" role="group" aria-labelledby="{getSlotAriaLabelledby(id)}" aria-describedby="{date}" id="{`${DataHooks.ROOT}-${id}`}" data-service-color-type="{servicesColorsType}">
      <div className="{classes.container}">
        <div className="{classes.col1}">
          <text data-hook="{DataHooks.TIME}" className="{classes.time}" tagName="{AccessibilityHtmlTags.Paragraph}" id="{`${DataHooks.TIME}-${id}`}">
            {Zeit}
          </text>

          <text data-hook="{DataHooks.TIME_WIDTH_HOLDER}" className="{`${classes.time}" ${classes.hidden}`}="" tagName="{AccessibilityHtmlTags.Paragraph}" aria-hidden="true">
            {timeWidthHolder}
          </text>
        </div>

        <div className="{classes.detailsContainer}">
          <div className="{classes.details}">
            <div className="{classes.col2}">
              <div className="{classes.detailsCol}">
                <text data-hook="{DataHooks.SERVICE_NAME}" tagName="{AccessibilityHtmlTags.Paragraph}" className="{classes.serviceName}" id="{`${DataHooks.SERVICE_NAME}-${id}`}">
                  {ServiceName}
                </text>
                <agendaslotdetail type="{AgendaSlotDetailType.LOCATION}" content="{location}"></agendaslotdetail>
              </div>
              <div className="{classes.detailsCol}">
                <agendaslotdetail type="{AgendaSlotDetailType.STAFF_MEMBER}" content="{staffMember}"></agendaslotdetail>
                <agendaslotdetail type="{AgendaSlotDetailType.DURATION}" content="{duration}"></agendaslotdetail>
                <agendaslotdetail type="{AgendaSlotDetailType.PRICE}" content="{price}"></agendaslotdetail>
              </div>
            </div>

            <div className="{classes.col3}">
              <agendaslotdetail type="{AgendaSlotDetailType.SPOTS_LEFT}" content="{spotsLeft}"></agendaslotdetail>

              {policyViolation ? (
                <text data-hook="{DataHooks.POLICY_VIOLATION}" tagName="{AccessibilityHtmlTags.Paragraph}" className="{classes.policyViolation}">
                  {policyViolation}
                </text>
              ) : null}

              {ctaViewModel ? (
                <button data-hook="{DataHooks.CTA}" disabled="{ctaViewModel.disabled}" fullWidth="{ctaViewModel.fullWidth}" loading="{ctaViewModel.loading}" onClick="{()" ==""> onSlotSelected([id])}
                  size={ButtonSize.small}
                  aria-describedby={`${DataHooks.ROOT}-${id}`}
                  wrapContent
                >
                  {ctaViewModel.label}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
</agendaslotprops></agendaslotdetailprops>